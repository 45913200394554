export default function sanitizeSentence(sentence: string): string {
  const wordsToStrip = ['and', 'or']
  const punctuationToStrip = [',', ':']

  const splitSentence = sentence.trim().split(' ')
  const lastWord = splitSentence[splitSentence.length - 1]
  if (!lastWord) {
    return sentence
  }

  if (wordsToStrip.includes(lastWord.toLowerCase())) {
    // Remove last word if it is indicative of text getting split up
    splitSentence.pop()
  }
  if (punctuationToStrip.includes(lastWord[lastWord.length - 1] ?? '')) {
    // Remove last char if it isbr indicative of text getting split up
    splitSentence[splitSentence.length - 1] = lastWord.slice(
      0,
      lastWord.length - 1,
    )
  }
  return splitSentence.join(' ')
}
