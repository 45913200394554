import type { ArrayElement } from '@lib/types/arrayElement'
import isVisibleCriterion, {
  isTrialProAdded,
} from '@modules/clinicalTrialEligibilityCriteria/helpers/isVisibleCriterion'
import { parseEligibilityCriteria } from '@modules/trials/helpers/parseEligibilityCriteria'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import sortBy from 'lodash/sortBy'
import { array, boolean, date, number, object, string, type TypeOf } from 'zod'

const ParsedCriterionTypeValidator = object({
  assertiveSummary: string().nullish(),
  criterion: string(),
  id: number().nullish(),
  isInclusion: boolean(),
  summaryHighlightedTerms: array(string()).nullish(),
  tags: array(string()),
  updatedAt: date().nullish(),
})

export type ParsedCriterionType = TypeOf<typeof ParsedCriterionTypeValidator>

export function extractCriterion(
  criterion:
    | string
    | ArrayElement<TrialAssociation['parsedEligibilityCriteria']>,
) {
  if (typeof criterion === 'string') {
    return extractNoSummaryCriterion(criterion)
  } else {
    return extractParsedCriterion(criterion)
  }
}

function extractParsedCriterion(
  criteria: ArrayElement<TrialAssociation['parsedEligibilityCriteria']>,
) {
  return {
    assertiveSummary: criteria.assertiveSummary,
    criterion: criteria.criterion,
    id: criteria.id,
    isInclusion: criteria.isInclusion ?? true,
    summaryHighlightedTerms: criteria.summaryHighlightedTerms,
    tags: criteria.tags,
    updatedAt: criteria.updatedAt,
  }
}

function extractNoSummaryCriterion(criterion: string) {
  return {
    assertiveSummary: undefined,
    criterion,
    id: null,
    isInclusion: true,
    summaryHighlightedTerms: undefined,
    tags: ['other'],
    updatedAt: new Date(),
  }
}

export default function getParsedEligibilityCriteriaForTrial(
  trial: TrialAssociation,
) {
  const isManagedTrial = isTrialProAdded({
    eligibilityCriteria: trial.parsedEligibilityCriteria,
  })
  const parsedCriteria: ParsedCriterionType[] =
    (trial.parsedEligibilityCriteria ?? []).length > 1
      ? trial.parsedEligibilityCriteria
          .filter((criterion) =>
            isVisibleCriterion({ criterion, isTrialProAdded: isManagedTrial }),
          )
          .map(extractCriterion)
      : parseEligibilityCriteria(trial.eligibilityCriteria ?? '').map(
          extractCriterion,
        )

  return sortBy(parsedCriteria, 'updatedAt')
}
